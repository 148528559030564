import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import gsap from 'gsap'
import * as dat from 'dat.gui'
import React from "react"
import ReactDOM from "react-dom";
import {App} from "./components/App"

declare global {
    interface Window { current?: any; currentFilter?: any; }
}

ReactDOM.render(<App />, document.getElementById("app"))


// ===========================================================
// WINDOW
// ===========================================================

// Set scroll to top on refresh
window.onbeforeunload = function () {
    window.scrollTo(0, 0);
}

// resize everything based on window size
const windowSize = {
    width: window.innerWidth,
    height: window.innerHeight
}
window.addEventListener('resize', () =>
{
    // Update window sizes
    windowSize.width = window.innerWidth
    windowSize.height = window.innerHeight

    // Update camera
    camera.aspect = windowSize.width / windowSize.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(windowSize.width, windowSize.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


// ===========================================================
// SCENE
// ===========================================================

const scene = new THREE.Scene()


// ===========================================================
// Renderer
// ===========================================================

const renderer = new THREE.WebGLRenderer({ alpha: true })
renderer.setSize( window.innerWidth, window.innerHeight )
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
document.body.appendChild( renderer.domElement )


// ===========================================================
// Lights
// ===========================================================

const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0xff9900, 1);
hemisphereLight.position.set(0, 0, 0)
hemisphereLight.intensity = 3;
scene.add(hemisphereLight)

const directionalLight = new THREE.DirectionalLight(0xd7cc99, 2)
directionalLight.position.set(0, 3, 6)
directionalLight.intensity = 1
scene.add(directionalLight)

const pointLight = new THREE.PointLight(0xff9900, 2)
pointLight.position.set(0, 0, 0)
pointLight.intensity = 1
scene.add(pointLight)


// ===========================================================
// Camera
// ===========================================================

const camera = new THREE.PerspectiveCamera(
    75, windowSize.width / windowSize.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 2
scene.add(camera)


// ===========================================================
// ASSETS
// ===========================================================

// Models
const MODEL = 'assets/models/mayubird3.gltf'

// Testures
const EMBER_TEXTURE = 'assets/textures/ember.png'
const PANO_TEXTURE = 'assets/images/pano3.jpg'
const FILTER_TEXTURE_1 = 'assets/images/filter_disguise.png'
const FILTER_TEXTURE_2 = 'assets/images/filter_eyes.png'
const FILTER_TEXTURE_3 = 'assets/images/filter_funglasses.png'


// ===========================================================
// CONSTANTS
// ===========================================================

const MODEL_OFFSET = -0.4
const MODEL_POSITION = { x: 0, y: MODEL_OFFSET, z: 0 }
const MODEL_ROTATION = {x: 0, y: 0, z: 0}
const MODEL_SCALE = 0.02

const MODEL_POSITION_2D = { x: 0.15, y: -0.1, z: 1.8 }
const MODEL_ROTATION_2D = { x: 0, y: -1.5, z: 0 }
const MODEL_SCALE_2D = 0.004

const MODEL_POSITION_360 = { x: 0, y: MODEL_OFFSET, z: 0 }
const MODEL_ROTATION_360 = MODEL_ROTATION
const MODEL_SCALE_360 = 0.015

const MODEL_POSITION_FILT = { x: -0.12, y: -1.75, z: 0 }
const MODEL_ROTATION_FILT = { x: -0.3, y: 0, z: -0.1 }
const MODEL_SCALE_FILT = 0.055
const FILTER_POSITION = { x: 0.03, y: 0.17, z: 1.3 }
const FILTER_ROTATION = { x: 0, y: 0, z: -0.15 }


// ===========================================================
// LOAD TEXTURES
// ===========================================================

const textureLoader = new THREE.TextureLoader()

const emberTexture = textureLoader.load(EMBER_TEXTURE,
    undefined, undefined, (err) => {console.log(err)})

const panoTexture = textureLoader.load(PANO_TEXTURE,
    undefined, undefined, (err) => {console.log(err)})

const filterTexture_1 = textureLoader.load(FILTER_TEXTURE_1,
    undefined, undefined, (err) => {console.log(err)})

const filterTexture_2 = textureLoader.load(FILTER_TEXTURE_2,
    undefined, undefined, (err) => {console.log(err)})

const filterTexture_3 = textureLoader.load(FILTER_TEXTURE_3,
    undefined, undefined, (err) => {console.log(err)})


// ===========================================================
// CREATE THREEJS OBJECTS
// ===========================================================

// Moving Embers

const embersGeometry = new THREE.BufferGeometry
const emberCount = 2000
const positionArray = new Float32Array(emberCount * 3) //xyz per star
const positionArrayOriginal = new Float32Array(emberCount * 3)
const velocityArray = new Float32Array(emberCount * 3)


// Set starting position, current position, and velocity of each ember
for (var i = 0; i < emberCount; i++) {

    // current position
    positionArray[i * 3] = (Math.random() - 0.5) * 4 + (window.innerWidth / 400)
    positionArray[i * 3 + 1] = (Math.random() - 0.5) * 4 - (window.innerHeight / 200)
    positionArray[i * 3 + 2] = (Math.random() - 0.5) * 3

    // starting position
    positionArrayOriginal[i * 3] = positionArray[i * 3]
    positionArrayOriginal[i * 3 + 1] = positionArray[i * 3 + 1]
    positionArrayOriginal[i * 3 + 2] = positionArray[i * 3 + 2]

    // velocity
    velocityArray[i * 3] = -1 * (Math.random() * 0.004 + 0.006)
    velocityArray[i * 3 + 1] = (Math.random()) * 0.01 + 0.005
    velocityArray[i * 3 + 2] = (Math.random()) * 0.01
}

embersGeometry.setAttribute('position', new THREE.BufferAttribute(positionArray, 3).setUsage( THREE.DynamicDrawUsage ))
embersGeometry.setAttribute('velocity', new THREE.BufferAttribute(velocityArray, 3))



// Static objects

const panoGeometry = new THREE.SphereBufferGeometry(10, 64, 64)
const transitionGeometry = new THREE.PlaneGeometry(50, window.innerHeight / 50 )
const filterGeometry = new THREE.PlaneGeometry(0.25, 0.25)


// ===========================================================
// CREATE MATERIALS FOR THREEJS OBJECTS
// ===========================================================

const embersMaterial = new THREE.PointsMaterial({
    size: 0.02,
    map: emberTexture,
    transparent: true
})

const panoMaterial = new THREE.MeshStandardMaterial({
    map: panoTexture,
    side: THREE.BackSide,
    roughness: 1
})

const transitionMaterial = new THREE.MeshBasicMaterial( {
    color: 0x000000,
})

const filterMaterial = new THREE.MeshBasicMaterial({
    map: filterTexture_1,
    transparent: true
})


// ===========================================================
// SET THREEJS MODEL CONTENT
// ===========================================================

// Added at start
const embers = new THREE.Points(embersGeometry,embersMaterial)
embers.position.z = 1
scene.add(embers)

// Set; added after scrolling
const pano = new THREE.Mesh(panoGeometry, panoMaterial)
pano.position.set(0, 0, 2)
pano.rotation.y = 2

const transitionPlane = new THREE.Mesh(transitionGeometry, transitionMaterial)
transitionPlane.position.z = -2
transitionPlane.rotation.z = 0.5

const filter = new THREE.Mesh(filterGeometry, filterMaterial)
filter.position.x = FILTER_POSITION.x
filter.position.z = FILTER_POSITION.z
filter.position.y = FILTER_POSITION.y
filter.rotation.z = FILTER_ROTATION.z


// ===========================================================
// ANIMATED MODEL
// ===========================================================

// variables
var model: THREE.Object3D
let modelReady = false
let mixer: THREE.AnimationMixer
const animationActions: THREE.AnimationAction[] = []
let activeAction: THREE.AnimationAction

// loaders
const loader = new GLTFLoader()
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath( '/examples/js/libs/draco/' );
loader.setDRACOLoader( dracoLoader );

loader.load(MODEL, (gltf) => {
    model = gltf.scene || gltf.scenes[0];
    const clips = gltf.animations || [];

    setModel(model)

    // Animate model
    mixer = new THREE.AnimationMixer(model)
    const animationAction = mixer.clipAction(clips[0])
    animationActions.push(animationAction)
    activeAction = animationActions[0]
    activeAction.play()
    scene.add(model);

    // COMMENT OUT FOLLOWING LINE TO REMOVE GUI
 //   addGui(model)
  },
  undefined,
  (error)=>{
      console.log(error)
  }
);

var setModel = ( object ) => {
    modelReady = true

    object.position.x = MODEL_POSITION.x;
    object.position.y = MODEL_POSITION.y;
    object.position.z = MODEL_POSITION.z;

    object.scale.x = MODEL_SCALE;
    object.scale.y = MODEL_SCALE;
    object.scale.z = MODEL_SCALE;
}

// var addGui = (object) => {
//     const gui = new dat.GUI()

//     // LIGHTS

//     var light = gui.addFolder('Hemisphere Light')
//     light.add(hemisphereLight.position, 'x').min(-6).max(6)
//     light.add(hemisphereLight.position, 'y').min(-6).max(6)
//     light.add(hemisphereLight.position, 'z').min(-6).max(6)
//     light.add(hemisphereLight, 'intensity').min(0).max(10)
//     var lightcolor = {color: 0x000000}
//     light.addColor(lightcolor, 'color')
//         .onChange(() => {hemisphereLight.color.set(lightcolor.color)})

//     var light2 = gui.addFolder('Directional Light')
//     light2.add(directionalLight.position, 'x').min(-6).max(6)
//     light2.add(directionalLight.position, 'y').min(-6).max(6)
//     light2.add(directionalLight.position, 'z').min(-6).max(6)
//     light2.add(directionalLight, 'intensity').min(0).max(10)
//     var light2color = {color: 0x000000}
//     light2.addColor(light2color, 'color')
//         .onChange(() => {directionalLight.color.set(light3color.color)})

//     var light3 = gui.addFolder('Point Light')
//     light3.add(pointLight.position, 'x').min(-6).max(6)
//     light3.add(pointLight.position, 'y').min(-6).max(6)
//     light3.add(pointLight.position, 'z').min(-6).max(6)
//     light3.add(pointLight, 'intensity').min(0).max(10)
//     var light3color = {color: 0x000000}
//     light3.addColor(light3color, 'color')
//         .onChange(() => {pointLight.color.set(light3color.color)})


//     // MODEL

//     var position = gui.addFolder('Position')
//     position.add(object.position, 'x').min(-50).max(50)
//     position.add(object.position, 'y').min(-50).max(50)
//     position.add(object.position, 'z').min(-50).max(50)

//     var rotation = gui.addFolder('Rotation')
//     rotation.add(object.rotation, 'x').min(-2 * Math.PI).max(2 * Math.PI)
//     rotation.add(object.rotation, 'y').min(-2 * Math.PI).max(2 * Math.PI)
//     rotation.add(object.rotation, 'z').min(-2 * Math.PI).max(2 * Math.PI)

//     var scale = gui.addFolder('Scale')
//     var newScale = {percentage: MODEL_SCALE * 100}
//     scale.add(newScale, 'percentage').min(0).max(100)
//         .onChange(()=>{
//             object.scale.x = newScale.percentage / 100
//             object.scale.y = newScale.percentage / 100
//             object.scale.z = newScale.percentage / 100
//         })
// }


// ===========================================================
// Animations
// ===========================================================

// ---------
// MOUSEMOVE
// ---------

var mouse = { x: innerWidth/2, y: innerHeight/2 }
var expectedPos = { x: 0, y: 0 }
var realPos = { x: 0, y: 0 }

addEventListener('mousemove', (e) => {
    mouse.x = e.x
    mouse.y = e.y
    expectedPos.x = -0.0003 * (mouse.x - innerWidth/2)
    expectedPos.y = 0.0003 * (mouse.y - innerHeight/2)
})


// -------------
// LIGHT FLICKER
// -------------

var flickerSpeeds = []

for (var i = 0; i < 1000; i++) {
    flickerSpeeds.push((Math.random() * 0.5) + 0.2)
}

var flickerIndex = 0
var previousFlickerIndex = -1

// --------------------------------------
// SECTION FLAGS
// --------------------------------------
var heroInit = false
var twoDInit = false
var panoInit = false
var panoRotate = 0
var filtInit = false
var modelFilterPos = -0.6
var filtX = 0
var filtBounce = false
var wait = {}



// ------------------
// CONSTANT ANIMATION
// ------------------

const clock = new THREE.Clock()

const tick = () => {

    // ================================================================
    // PRE RENDER CHANGES
    // ================================================================

    if (modelReady) {

        // --------------------------------------
        // HERO
        // --------------------------------------

        // AT HERO (UPON PAGE LOAD)
        // Set model contents
        if (window.current === 'hero' && heroInit) {
            if (model.position.x !== realPos.x) {
                model.position.x = realPos.x
            }

            if (model.position.y !== realPos.y + MODEL_OFFSET) {
                model.position.y = realPos.y + MODEL_OFFSET
            }

            if (model.position.z !== 0) {
                model.position.z = 0
            }

            if (model.rotation.z !== MODEL_POSITION.z) {
                model.rotation.z = MODEL_POSITION.z
            }

            if (model.scale.x !== MODEL_SCALE ||
                model.scale.y !== MODEL_SCALE ||
                model.scale.z !== MODEL_SCALE) {
                model.scale.set(MODEL_SCALE, MODEL_SCALE, MODEL_SCALE)
            }
        }

        // EXIT HERO
        if (window.current !== 'hero' && heroInit) {
            heroInit = false
        }

        // --------------------------------------
        // 2D SECTION
        // --------------------------------------

        // ENTER 2D FIGHT
        if (window.current === 'anim' && twoDInit === false) {
            twoDInit = true

            // Transition animation
            gsap.to(wait, {duration: 0.2, onComplete: () => {
                if (window.current === 'anim') {

                    // BRING 2D FIGHTER INTO VIEW
                    gsap.to("#twoDFighter", {
                        opacity: 1,
                        transform: 'scale(1)',
                        duration: 0.3
                    })

                    // GET 3D MODEL INTO FIGHTING POSITION
                    var duration = 1
                    gsap.to(model.position, {
                        x: MODEL_POSITION_2D.x,
                        y: MODEL_POSITION_2D.y,
                        z: MODEL_POSITION_2D.z,
                        duration})
                    gsap.to(model.rotation, {
                        x: MODEL_ROTATION_2D.x,
                        y: MODEL_ROTATION_2D.y,
                        z: MODEL_ROTATION_2D.z,
                        duration})
                    gsap.to(model.scale, {
                        x: MODEL_SCALE_2D,
                        y: MODEL_SCALE_2D,
                        z: MODEL_SCALE_2D,
                        duration})
                }
            }})

        // EXIT 2D FIGHT
        } else if (window.current !== 'anim' && twoDInit === true){

            // REMOVE 2D FIGHTER FROM VIEW
            gsap.to("#twoDFighter", {opacity: 0, duration: 0.5, onComplete: () => {
                gsap.to("#twoDFighter", {transform: 'scale(5)', duration: 0.01})
            }})

            // GET 3D MODEL BACK INTO POSITION
            // Back to HERO
            if (window.current === 'hero'){
                // Positions based on mousemove
                gsap.to(model.position, {
                    x: realPos.x,
                    y: realPos.y + MODEL_OFFSET,
                    z: MODEL_POSITION.z,
                    onComplete: () => {
                        twoDInit = false
                        heroInit = true
                }})
                gsap.to(model.scale, {
                    x: MODEL_SCALE,
                    y: MODEL_SCALE,
                    z: MODEL_SCALE })
            // Forward to 360
            } else {
                gsap.to(model.position, {
                    x: MODEL_POSITION_360.x,
                    y: MODEL_POSITION_360.y,
                    z: MODEL_POSITION_360.z,
                    onComplete: () => {
                        twoDInit = false
                }})
                gsap.to(model.scale, {
                    x: MODEL_SCALE_360,
                    y: MODEL_SCALE_360,
                    z: MODEL_SCALE_360 })
            }

            // Set model rotation to default regardless
            gsap.to(model.rotation, {
                x: MODEL_ROTATION.x,
                y: MODEL_ROTATION.y,
                z: MODEL_ROTATION.z
            })
        }


        // --------------------------------------
        // 360 SECTION
        // --------------------------------------

        // ENTER 360
        if (window.current === '360d' && panoInit === false) {

            // BLACK TRANSITION PLANE TO BRING 360 IMAGE INTO VIEW
            // Transition after 0.5 seconds to avoid big flicker
            gsap.to(wait, {duration: 0.5, onComplete: ()=>{
                scene.add(transitionPlane)
                // do transition if 360d hasn't been scrolled past
                if (window.current === '360d') {
                    panoInit = true
                    scene.add(pano)
                    gsap.to(transitionPlane.position, {y: window.innerHeight/25, duration: 1,
                        onComplete: ()=>{
                            scene.remove(transitionPlane)
                    }})
                } else {
                    scene.remove(transitionPlane)
                }
            }})

        // EXIT 360
        } else if (window.current !== '360d' && panoInit === true) {
            panoInit = false
            transitionPlane.position.y = window.innerHeight/25
            scene.add(transitionPlane)
            // TRANSITION BACK TO NORMAL
            gsap.to(transitionPlane.position, {y: 0, duration: 0.5, onComplete: ()=>{
                scene.remove(pano)
                scene.remove(transitionPlane)
            }})

            // RESET CAMERA
            gsap.to(camera.rotation, {x: 0, y: 0, z: 0, duration: 0.5})
        }

        // AT 360 after transition
        if (window.current === '360d' && panoInit) {
            if (model.position.x !== MODEL_POSITION_360.x) {
                model.position.x = MODEL_POSITION_360.x
            }

            if (model.position.y !== MODEL_POSITION_360.y) {
                model.position.y = MODEL_POSITION_360.y
            }

            if (model.position.z !== MODEL_POSITION_360.z) {
                model.position.z = MODEL_POSITION_360.z
            }

            if (model.rotation.x !== MODEL_ROTATION_360.x ||
                model.rotation.y !== MODEL_ROTATION_360.y ||
                model.rotation.z !== MODEL_ROTATION_360.z ) {
                model.rotation.set(MODEL_ROTATION_360.x, MODEL_ROTATION_360.y, MODEL_ROTATION_360.z)
            }

            if (model.scale.x !== MODEL_SCALE_360 ||
                model.scale.y !== MODEL_SCALE_360 ||
                model.scale.z !== MODEL_SCALE_360) {
                model.scale.set(MODEL_SCALE_360, MODEL_SCALE_360, MODEL_SCALE_360)
            }
        }

        // --------------------------------------
        // FILTERING SECTION
        // --------------------------------------

        // ENTER FILTERING
        if (window.current === 'filt' && filtInit === false) {
            filtInit = true

            gsap.to(wait, {duration: 0.2, onComplete: () => {
                if (window.current === 'filt') {

                    var duration = 1
                    activeAction.fadeOut(duration)

                    // GET 3D MODEL INTO FILTER POSITION
                    gsap.to(model.position, {
                        x: MODEL_POSITION_FILT.x,
                        y: MODEL_POSITION_FILT.y,
                        z: MODEL_POSITION_FILT.z,
                        duration})
                    gsap.to(model.rotation, {
                        x: MODEL_ROTATION_FILT.x ,
                        y: MODEL_ROTATION_FILT.y,
                        z: MODEL_ROTATION_FILT.z,
                        duration})
                    gsap.to(model.scale, {
                        x: MODEL_SCALE_FILT,
                        y: MODEL_SCALE_FILT,
                        z: MODEL_SCALE_FILT,
                        duration,
                        onComplete: () => {
                            if (window.current === 'filt') {
                                scene.add(filter)
                                filtBounce = true
                            }
                    }})
                }
            }})

        // EXIT FILTERING
        } else if (window.current !== 'filt' && filtInit === true) {

            scene.remove(filter)
            filtBounce = false
            filtInit = false
            var duration = 1
            activeAction.reset()
            activeAction.fadeIn(0.5)
            activeAction.play()

            gsap.to(model.rotation, {
                x: MODEL_ROTATION.x,
                y: MODEL_ROTATION.y,
                z: MODEL_ROTATION.z, duration})
            if (window.current === "360d") {
                gsap.to(model.position, {
                    x: MODEL_POSITION_360.x,
                    y: MODEL_POSITION_360.y,
                    z: MODEL_POSITION_360.z,
                    duration})
                gsap.to(model.scale, {
                    x: MODEL_SCALE_360,
                    y: MODEL_SCALE_360,
                    z: MODEL_SCALE_360,
                    duration: 0.5})
            } else {
                gsap.to(model.position, {
                    x: realPos.x,
                    y: realPos.y + MODEL_OFFSET,
                    z: MODEL_POSITION.z,
                    duration})
                gsap.to(model.scale, {
                    x: MODEL_SCALE,
                    y: MODEL_SCALE,
                    z: MODEL_SCALE,
                    duration})
            }
        }
    }

    // ================================================================
    // RENDER
    // ================================================================

    renderer.render(scene, camera)
    window.requestAnimationFrame(tick)
    if (modelReady) {
        mixer.update(clock.getDelta())
        // mixer2.update(clock.getDelta())
    }


    // ================================================================
    // POST RENDER CHANGES
    // ================================================================

    if (modelReady) {

        // --------------------------------------
        // MOUSE MOVE ANIMATION
        // --------------------------------------

        // DISTANCE FOR MODEL TO TRAVEL FROM CURRENT TO EXPECTED
        if (true) {
            var distanceX = (realPos.x - expectedPos.x)
            var distanceY = (realPos.y - expectedPos.y)
            var distance = Math.sqrt(distanceX ** 2 + distanceY ** 2)

            // SLOWLY MOVE MODEL TO EXPECTED
            if ( distance > 0.001) {
                var angle = Math.atan2(distanceY, distanceX)
                realPos.x -= distance ** 2 * Math.cos(angle)
                realPos.y -= distance ** 2 * Math.sin(angle)
            }

            // SET MODELS TO UPDATED POSITIONS
            if (model && !twoDInit && !panoInit && !filtInit) {
                model.position.x = realPos.x
                model.position.y = realPos.y + MODEL_OFFSET
            }
        }

        // --------------------------------------
        // LIGHT FLICKER
        // --------------------------------------

        if (flickerIndex !== previousFlickerIndex) {
            previousFlickerIndex = flickerIndex
            gsap.to(pointLight, {
                intensity: (flickerIndex % 2 === 0 ? Math.random()
                    * 0.5 + 0.5 : Math.random() * 0.5),
                duration: flickerSpeeds[flickerIndex],
                onComplete: () => {
                flickerIndex++
                if (flickerIndex === 1000) flickerIndex = 0
            }})
        }

        // --------------------------------------
        // EMBER PARTICLES
        // --------------------------------------

        for (var i = 0; i < emberCount; i++){

            // MOVE EMBERS
            positionArray[i * 3] += velocityArray[i * 3]
            positionArray[i * 3 + 1] += velocityArray[i * 3 + 1]
            positionArray[i * 3 + 2] += velocityArray[i * 3 + 2]

            // IF EMBERS GO OUT OF BOUNDS
            if (positionArray[i * 3] < (-1 * camera.fov / camera.getFilmWidth())
                || positionArray[i * 3 + 1] > ( camera.fov / camera.getFilmHeight())
            ) {
                // RESET TO ORIGINAL POINT
                positionArray[i * 3] = positionArrayOriginal[i * 3]
                positionArray[i * 3 + 1] = positionArrayOriginal[i * 3 + 1]
                positionArray[i * 3 + 2] = positionArrayOriginal[i * 3 + 2]
                }
        }
        embers.geometry.attributes.position.needsUpdate = true;

        // --------------------------------------
        // 360 SECTION
        // --------------------------------------

        if (window.current === '360d' && panoInit) {
            panoRotate += 0.003
            panoRotate = panoRotate % (2 * Math.PI)
            camera.rotation.y = -1 * panoRotate
        } else {
            panoRotate = 0
        }

        // --------------------------------------
        // FILTERING
        // --------------------------------------

        if (window.current === 'filt' && filtBounce) {
            model.position.y = 0.05 * Math.sin(filtX) + MODEL_POSITION_FILT.y
            filter.position.y = 0.05 * Math.sin(filtX) + FILTER_POSITION.y
            filtX += 0.05
            if (window.currentFilter === 'eyes') {
                filter.material.map = filterTexture_2
            } else if (window.currentFilter === 'funglasses'){
                filter.material.map = filterTexture_3
            } else {
                filter.material.map = filterTexture_1
            }
            filter.material.map.needsUpdate = true
        }
    }
}
tick()