import React, { useRef, useState, useEffect } from "react";
import useElementOnScreen from "../helpers/useElementOnScreen";

export const Hero = (props) => {
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: props.threshold,
  });

  useEffect(() => {
    if (isVisible) props.updateCurrent("hero");
  }, [isVisible]);

  return (
    <section className="hero" ref={containerRef}>
      <div className="content">
        <h1 tabIndex="0" style={{}}>
          We are Mayu, a mixed media creative consulting firm, focused on the{" "}
          <strong>Internet of Things</strong> &{" "}
          <strong>interactive experiences.</strong>
        </h1>
      </div>
    </section>
  );
};

export default Hero;
