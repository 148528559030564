import React, { useEffect, useState } from "react";
import useElementOnScreen from "../helpers/useElementOnScreen";

export const Filtering = (props) => {
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: props.threshold,
  });

  var [filter, changeFilter] = useState("disguise");

  useEffect(() => {
    if (isVisible) props.updateCurrent("filt");
  }, [isVisible]);

  // useEffect(()=> {
  //   changeFilter(window.currentFilter)
  //   console.log('hello')
  // }, [window.currentFilter])

  return (
    <section className="filtering" ref={containerRef}>
      <div className="content">
        <h1 tabIndex="0">And a little fun never hurt anyone.</h1>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <button
            className={`filterBtn ${filter === "disguise" ? "selected" : ""}`}
            onClick={() => {
              window.currentFilter = "disguise";
              changeFilter("disguise");
            }}
          >
            Disguise
          </button>
          <button
            className={`filterBtn ${filter === "eyes" ? "selected" : ""}`}
            onClick={() => {
              window.currentFilter = "eyes";
              changeFilter("eyes");
            }}
          >
            Anime Eyes
          </button>
          <button
            className={`filterBtn ${filter === "funglasses" ? "selected" : ""}`}
            onClick={() => {
              window.currentFilter = "funglasses";
              changeFilter("funglasses");
            }}
          >
            Funglasses
          </button>
        </div>
      </div>
    </section>
  );
};

export default Filtering;
