import React, { useRef, useState, useEffect } from 'react'

const useElementOnScreen = (options) => {
  const containerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  const cb = (entries) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(cb, options)

    if (containerRef.current) observer.observe(containerRef.current)

    return (()=> {
      if (containerRef.current) observer.unobserve(containerRef.current)
    })
  }, [containerRef, options])

  return [containerRef, isVisible]
}

export default useElementOnScreen